.App {
  text-align: center;
}


.App-header {
  background-color: #282c34;
  background-image: url('./images/background.jpg');
  background-position: center center;
  max-width: 100%;
  min-height: 100vh;

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: end;
  color: white;
}

.App-body {
  background-color: #020403;
  color: white;
  font-size: large;
}

.link-custom {
  color: #9c658f;
}

.link-custom:hover {
  color: #9c658f
}

.navbar-custom {
  background-color: #0f1316;
}


body {
  background: #0f1316;
}
